<template>
  <div class="p-3">
    <div class="limit p-3 pt-0">
      <h1 class="title-header">Marketing</h1>
      <img class="mt-36" src="../../assets/images/contents.png" alt="">
    </div>
  </div>
</template>

<style lang="scss" scoped>
.limit {
  max-width: 100%;

  .container-service {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .row-service {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: calc(95% - 200px);
      max-width: 650px;
    }

    input {
      width: 200px;
    }

    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 220px);
      gap: 10px;

      @media (max-width: 970px) {
        width: 100%;
        margin-bottom: 10px;
      }

      @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
      }
    }
  }
}
</style>